import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"

//components
import Layout from "../components/layout"
import Sidebar from "../components/sidebar"
import PageIntroText from "../components/pageIntroText"
import AboutServicesSection from "../components/aboutServicesSection"
import AboutServicesScrollMenu from "../components/aboutServicesSrollMenu"

const Services = ({ data }) => {
  return (
    <Layout footer>
      <Sidebar pageTitle={"SERVICES"}>
        <AboutServicesScrollMenu
          sections={data.sanityServicesPage.servicesSections}
        />
      </Sidebar>
      {/* Main Content */}
      <div className="md:col-start-5 md:col-span-7">
        <PageIntroText>{data.sanityServicesPage.introText}</PageIntroText>
        {data.sanityServicesPage.servicesSections.map(section => {
          return (
            <AboutServicesSection
              key={section.navigationTitle}
              sectionId={section.navigationTitle}
              sectionImage={section.sectionImage}
              section={section}
            />
          )
        })}
      </div>
    </Layout>
  )
}

export default Services
export const Head = () => <Seo title="Services" />
export const query = graphql`
  query {
    sanityServicesPage {
      introText
      servicesSections {
        textTitle
        navigationTitle
        _rawSectionText
        sectionImage {
          altText
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`

import React from "react"
import { motion } from "framer-motion"
import { GatsbyImage } from "gatsby-plugin-image"
import { PortableText } from "@portabletext/react"
import { useMediaQuery } from "react-responsive"

//utils
import { variants } from "../utils/framerUtils"

//Portable text components
const portableComponents = {
  block: {
    normal: ({ children }) => (
      <motion.p
        className="pb-lead-1"
        variants={variants.fadeUpandInStaggerChildren}
      >
        {children}
      </motion.p>
    ),
  },
  list: {
    number: ({ children }) => (
      <motion.ol variants={variants.fadeUpandInStaggerChildren}>
        {children.map((child, index) => (
          <motion.li
            key={index}
            variants={variants.fadeUpandInStaggerChildren}
            className="relative flex"
          >
            <span className="text-love-grey md:left-[calc(-1rem*1.4)] md:absolute pr-lead-1 md:pr-0">
              {index + 1}
            </span>
            <ul>{child}</ul>
          </motion.li>
        ))}
      </motion.ol>
    ),
  },
}

const AboutServicesSection = ({ sectionId, sectionImage, section }) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })
  return (
    <motion.section
      variants={variants.fadeUpandInStaggerChildren}
      initial="hidden"
      whileInView="visible"
      exit="exit"
      viewport={{ once: true, margin: "0px 0px 0px -300px" }}
      id={sectionId}
      className="md:grid md:grid-cols-7 gap-lead-1 md:pb-lead-16"
    >
      <motion.div
        variants={variants.fadeUpandInStaggerChildren}
        className="md:col-span-3 my-lead-2 md:my-lead-0"
      >
        <GatsbyImage
          image={sectionImage?.asset.gatsbyImageData}
          alt={
            sectionImage.altText ??
            "An image on loveart.com that has no alternative text"
          }
          objectFit={"contain"}
          objectPosition={"50% 0%"}
        />
      </motion.div>
      <motion.div
        variants={variants.fadeUpandInStaggerChildren}
        className="col-span-3 col-start-5 font-regular"
      >
        {isMobile && (
          <motion.h2
            variants={variants.fadeUpandInStaggerChildren}
            className="font-light text-mobile-medium mb-lead-1"
          >
            {section.navigationTitle.toUpperCase()}
          </motion.h2>
        )}
        {section.textTitle && (
          <motion.h3
            variants={variants.fadeUpandInStaggerChildren}
            className="text-love-grey mb-lead-1"
          >
            {section.textTitle.toUpperCase()}
          </motion.h3>
        )}
        <PortableText
          value={section._rawSectionText}
          components={portableComponents}
        />
      </motion.div>
    </motion.section>
  )
}

export default AboutServicesSection

import React from "react"
import { motion } from "framer-motion"
import { Link as ScrollLink } from "react-scroll"

//utils
import { variants } from "../utils/framerUtils"

const AboutServicesScrollMenu = ({ sections }) => {
  return (
    <motion.ul variants={variants.fadeUpandInStaggerChildren}>
      {sections.map(section => {
        return (
          <motion.li
            variants={variants.fadeUpandInStaggerChildren}
            key={section.navigationTitle}
            className="font-light cursor-pointer text-love-grey md:text-desktop-medium text-mobile-medium leading-medium hover:text-black"
          >
            <ScrollLink
              to={section.navigationTitle}
              spy={true}
              smooth={true}
              duration={500}
              offset={-315}
              activeClass="text-black"
            >
              {section.navigationTitle.toUpperCase()}
            </ScrollLink>
          </motion.li>
        )
      })}
    </motion.ul>
  )
}

export default AboutServicesScrollMenu
